import CryptoJS from 'crypto-js'
const keyStrs = 'abcdsxyzhkj12345'
const keyIv = 'abcdsxyzhkj12345'
export default { // 加密
  encrypt(word) {
    // cbc加密
    let key = keyStrs
    let iv = keyIv
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    const srcs = CryptoJS.enc.Utf8.parse(word)
    // 加密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    // 返回base64
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  },
  // 解密
  decrypt(word) {
    let key = keyStrs
    let iv = keyIv
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    const base64 = CryptoJS.enc.Base64.parse(word)
    const src = CryptoJS.enc.Base64.stringify(base64)
    // 解密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）
    const decrypt = CryptoJS.AES.decrypt(src, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()
  }
}
