import axios from 'axios'
import AES from './AES'
import { Toast } from 'vant'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 8000
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Authorization'] = 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55Ijoic3hzbXN6IiwiY3VzdG9tIjoxLCJleHAiOjE2NTMzODM1ODgsImlhdCI6MTY1MzM3NjM4OCwiaXNzIjoieXRoX2FkbWlubWluIiwicGxhdGZvcm0iOiJQQyIsInVzZXJfaWQiOjUwfQ.bDHTwgxslyde_MnkATlQv4jFnvGgAei2gX3UFlax_xQ'
    config.headers['c'] = 'sxsmsz'

    console.log(config.params)
    // get请求添加参数时进行AES加密
    if (config.params) {
      var encryptsparams = config.params
      console.log('-0000', config.params)
      config.params = { b: encodeURIComponent(AES.encrypt(JSON.stringify(encryptsparams))) }
      console.log('-1111', encodeURIComponent(AES.encrypt(JSON.stringify(encryptsparams))))
    }

    // post请求添加数据添加到data里时进行AES加密
    if (config.data) {
      // if (config.data.session_id) {
      //   config.headers['rere'] = config.data.session_id
      // }
      if (config.data) {
        var encryptsdata = config.data
        console.log('-2222', config.data)
        config.data = { b: AES.encrypt(JSON.stringify(encryptsdata)) }
        console.log('-3333', AES.encrypt(JSON.stringify(encryptsdata)))
      }
      // if (config.data.data) {
      //   config.data = config.data.data
      // }
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 20000 && res.code !== 0 && res.code !== 520900) {
      Toast.fail(res.msg)
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    return Promise.reject(error)
  }
)

export default service
